
const BUCKET_BASE_URL = `https://${process.env.REACT_APP_GENERAL_ASSETS_BUCKET}.s3.${process.env.REACT_APP_AWS_S3_REGION}.amazonaws.com`;

const BUCKET_IMAGES_URL = `${BUCKET_BASE_URL}/images`;
const BUCKET_ICONS_URL = `${BUCKET_BASE_URL}/icons_status`;
const BUCKET_LOGO_URL = `${BUCKET_BASE_URL}/logo_immployer`;

export const iconAssets = {
  Dots3HorizCircle: {
    grey: `${BUCKET_ICONS_URL}/3_dots_horiz_circle_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/3_dots_horiz_circle_primary.svg`,
    success: `${BUCKET_ICONS_URL}/3_dots_horiz_circle_success.svg`,
    white: `${BUCKET_ICONS_URL}/3_dots_horiz_circle_white.svg`,
    error: `${BUCKET_ICONS_URL}/3_dots_horiz_circle_error.svg`,
  },
  Dots3Vert: {
    grey: `${BUCKET_ICONS_URL}/3_dots_vert_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/3_dots_vert_primary.svg`,
    success: `${BUCKET_ICONS_URL}/3_dots_vert_success.svg`,
    white: `${BUCKET_ICONS_URL}/3_dots_vert_white.svg`,
    error: `${BUCKET_ICONS_URL}/3_dots_vert_error.svg`,
  },
  ArrowDown: {
    grey: `${BUCKET_ICONS_URL}/arrow_down_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/arrow_down_primary.svg`,
    success: `${BUCKET_ICONS_URL}/arrow_down_success.svg`,
    white: `${BUCKET_ICONS_URL}/arrow_down_white.svg`,
    error: `${BUCKET_ICONS_URL}/arrow_down_error.svg`,
  },
  Exchange: {
    grey: `${BUCKET_ICONS_URL}/exchange_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/exchange_primary.svg`,
    success: `${BUCKET_ICONS_URL}/exchange_success.svg`,
    white: `${BUCKET_ICONS_URL}/exchange_white.svg`,
    error: `${BUCKET_ICONS_URL}/exchange_error.svg`,
  },
  Bell: {
    grey: `${BUCKET_ICONS_URL}/bell_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/bell_primary.svg`,
    success: `${BUCKET_ICONS_URL}/bell_success.svg`,
    white: `${BUCKET_ICONS_URL}/bell_white.svg`,
    error: `${BUCKET_ICONS_URL}/bell_error.svg`,
  },
  Bin: {
    grey: `${BUCKET_ICONS_URL}/bin_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/bin_primary.svg`,
    success: `${BUCKET_ICONS_URL}/bin_success.svg`,
    white: `${BUCKET_ICONS_URL}/bin_white.svg`,
    error: `${BUCKET_ICONS_URL}/bin_error.svg`,
  },
  Briefcase: {
    grey: `${BUCKET_ICONS_URL}/briefcase_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/briefcase_primary.svg`,
    success: `${BUCKET_ICONS_URL}/briefcase_success.svg`,
    white: `${BUCKET_ICONS_URL}/briefcase_white.svg`,
    error: `${BUCKET_ICONS_URL}/briefcase_error.svg`,
  },
  Calender: {
    grey: `${BUCKET_ICONS_URL}/calender_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/calender_primary.svg`,
    success: `${BUCKET_ICONS_URL}/calender_success.svg`,
    white: `${BUCKET_ICONS_URL}/calender_white.svg`,
    error: `${BUCKET_ICONS_URL}/calender_error.svg`,
  },
  Check: {
    grey: `${BUCKET_ICONS_URL}/check_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/check_primary.svg`,
    success: `${BUCKET_ICONS_URL}/check_success.svg`,
    white: `${BUCKET_ICONS_URL}/check_white.svg`,
    error: `${BUCKET_ICONS_URL}/check_error.svg`,
  },
  CheckmarkCircle: {
    grey: `${BUCKET_ICONS_URL}/checkmark_circle_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/checkmark_circle_primary.svg`,
    success: `${BUCKET_ICONS_URL}/checkmark_circle_success.svg`,
    white: `${BUCKET_ICONS_URL}/checkmark_circle_white.svg`,
    error: `${BUCKET_ICONS_URL}/checkmark_circle_error.svg`,
  },
  ChevronDown: {
    grey: `${BUCKET_ICONS_URL}/chevron_down_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/chevron_down_primary.svg`,
    success: `${BUCKET_ICONS_URL}/chevron_down_success.svg`,
    white: `${BUCKET_ICONS_URL}/chevron_down_white.svg`,
    error: `${BUCKET_ICONS_URL}/chevron_down_error.svg`,
  },
  CrossCircle: {
    grey: `${BUCKET_ICONS_URL}/cross_circle_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/cross_circle_primary.svg`,
    success: `${BUCKET_ICONS_URL}/cross_circle_success.svg`,
    white: `${BUCKET_ICONS_URL}/cross_circle_white.svg`,
    error: `${BUCKET_ICONS_URL}/cross_circle_error.svg`,
  },
  Draft: {
    grey: `${BUCKET_ICONS_URL}/draft_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/draft_primary.svg`,
    success: `${BUCKET_ICONS_URL}/draft_success.svg`,
    white: `${BUCKET_ICONS_URL}/draft_white.svg`,
    error: `${BUCKET_ICONS_URL}/draft_error.svg`,
  },
  Faces: {
    grey: `${BUCKET_ICONS_URL}/faces_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/faces_primary.svg`,
    success: `${BUCKET_ICONS_URL}/faces_success.svg`,
    white: `${BUCKET_ICONS_URL}/faces_white.svg`,
    error: `${BUCKET_ICONS_URL}/faces_error.svg`,
  },
  FacesCircle: {
    grey: `${BUCKET_ICONS_URL}/faces_circle_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/faces_circle_primary.svg`,
    success: `${BUCKET_ICONS_URL}/faces_circle_success.svg`,
    white: `${BUCKET_ICONS_URL}/faces_circle_white.svg`,
    error: `${BUCKET_ICONS_URL}/faces_circle_error.svg`,
  },
  Home: {
    grey: `${BUCKET_ICONS_URL}/home_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/home_primary.svg`,
    success: `${BUCKET_ICONS_URL}/home_success.svg`,
    white: `${BUCKET_ICONS_URL}/home_white.svg`,
    error: `${BUCKET_ICONS_URL}/home_error.svg`,
  },
  InfoAsk: {
    grey: `${BUCKET_ICONS_URL}/info_ask_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/info_ask_primary.svg`,
    success: `${BUCKET_ICONS_URL}/info_ask_success.svg`,
    white: `${BUCKET_ICONS_URL}/info_ask_white.svg`,
    error: `${BUCKET_ICONS_URL}/info_ask_error.svg`,
  },
  Logout: {
    grey: `${BUCKET_ICONS_URL}/logout_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/logout_primary.svg`,
    success: `${BUCKET_ICONS_URL}/logout_success.svg`,
    white: `${BUCKET_ICONS_URL}/logout_white.svg`,
    error: `${BUCKET_ICONS_URL}/logout_error.svg`,
  },
  Pad: {
    grey: `${BUCKET_ICONS_URL}/pad_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/pad_primary.svg`,
    success: `${BUCKET_ICONS_URL}/pad_success.svg`,
    white: `${BUCKET_ICONS_URL}/pad_white.svg`,
    error: `${BUCKET_ICONS_URL}/pad_error.svg`,
  },
  Pencil: {
    grey: `${BUCKET_ICONS_URL}/pencil_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/pencil_primary.svg`,
    success: `${BUCKET_ICONS_URL}/pencil_success.svg`,
    white: `${BUCKET_ICONS_URL}/pencil_white.svg`,
    error: `${BUCKET_ICONS_URL}/pencil_error.svg`,
  },
  Plus: {
    grey: `${BUCKET_ICONS_URL}/plus_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/plus_primary.svg`,
    success: `${BUCKET_ICONS_URL}/plus_success.svg`,
    white: `${BUCKET_ICONS_URL}/plus_white.svg`,
    error: `${BUCKET_ICONS_URL}/plus_error.svg`,
  },
  Roles: {
    grey: `${BUCKET_ICONS_URL}/roles_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/roles_primary.svg`,
    success: `${BUCKET_ICONS_URL}/roles_success.svg`,
    white: `${BUCKET_ICONS_URL}/roles_white.svg`,
    error: `${BUCKET_ICONS_URL}/roles_error.svg`,
  },
  Search: {
    grey: `${BUCKET_ICONS_URL}/search_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/search_primary.svg`,
    success: `${BUCKET_ICONS_URL}/search_success.svg`,
    white: `${BUCKET_ICONS_URL}/search_white.svg`,
    error: `${BUCKET_ICONS_URL}/search_error.svg`,
  },
  Settings: {
    grey: `${BUCKET_ICONS_URL}/settings_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/settings_primary.svg`,
    success: `${BUCKET_ICONS_URL}/settings_success.svg`,
    white: `${BUCKET_ICONS_URL}/settings_white.svg`,
    error: `${BUCKET_ICONS_URL}/settings_error.svg`,
  },
  Tag: {
    grey: `${BUCKET_ICONS_URL}/tag_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/tag_primary.svg`,
    success: `${BUCKET_ICONS_URL}/tag_success.svg`,
    white: `${BUCKET_ICONS_URL}/tag_white.svg`,
    error: `${BUCKET_ICONS_URL}/tag_error.svg`,
  },
  TriangleDown: {
    grey: `${BUCKET_ICONS_URL}/triangle_down_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/triangle_down_primary.svg`,
    success: `${BUCKET_ICONS_URL}/triangle_down_success.svg`,
    white: `${BUCKET_ICONS_URL}/triangle_down_white.svg`,
    error: `${BUCKET_ICONS_URL}/triangle_down_error.svg`,
  },
  WarningTriangle: {
    grey: `${BUCKET_ICONS_URL}/warning_triangle_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/warning_triangle_primary.svg`,
    success: `${BUCKET_ICONS_URL}/warning_triangle_success.svg`,
    white: `${BUCKET_ICONS_URL}/warning_triangle_white.svg`,
    error: `${BUCKET_ICONS_URL}/warning_triangle_error.svg`,
  },

  Download: {
    grey: `${BUCKET_ICONS_URL}/download_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/download_primary.svg`,
    success: `${BUCKET_ICONS_URL}/download_success.svg`,
    white: `${BUCKET_ICONS_URL}/download_white.svg`,
    error: `${BUCKET_ICONS_URL}/download_error.svg`,
  },

  CheckCircleOutline: {
    grey: `${BUCKET_ICONS_URL}/check_circle_outline_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/check_circle_outline_primary.svg`,
    success: `${BUCKET_ICONS_URL}/check_circle_outline_success.svg`,
    white: `${BUCKET_ICONS_URL}/check_circle_outline_white.svg`,
    error: `${BUCKET_ICONS_URL}/check_circle_outline_error.svg`,
  },

  Filter: {
    grey: `${BUCKET_ICONS_URL}/filter_grey.svg`,
    primary: `${BUCKET_ICONS_URL}/filter_primary.svg`,
    success: `${BUCKET_ICONS_URL}/filter_success.svg`,
    white: `${BUCKET_ICONS_URL}/filter_white.svg`,
    error: `${BUCKET_ICONS_URL}/filter_error.svg`,
  },
  Heart: {
    heart_fill: `${BUCKET_ICONS_URL}/heart_fill.svg`,
    heart_outline: `${BUCKET_ICONS_URL}/heart_outline.svg`,
  }
};

export const imageAssets = {
  GlobalTalentStream: `${BUCKET_IMAGES_URL}/glabal_talent_stream.png`,
  HappySearch: `${BUCKET_IMAGES_URL}/happy_search.png`,
  Chart: `${BUCKET_IMAGES_URL}/chart.png`,
  FlyingPaperPlane: `${BUCKET_IMAGES_URL}/flying_paper_plane.png`,
};

export const logoAssets = {
  Logo: `${BUCKET_LOGO_URL}/logo.svg`,
  Monogram: `${BUCKET_LOGO_URL}/monogram.svg`,
};
