import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSessionData } from '../../store/slices/global';

declare global {
    interface Window {
        HubSpotConversations: any;
    }
};

const HubSpotChat = () => {
    const sessionData = useSelector(getSessionData());

    useEffect(() => {
        const loadHubSpotChat = () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'hs-script-loader';
            script.async = true;
            script.defer = true;
            script.src = process.env.REACT_APP_HUBSPOT_CHAT_URL as string;
            document.body.appendChild(script);

            script.onload = () => {
                if (window.HubSpotConversations) {
                    window.HubSpotConversations.clear();
                    window.HubSpotConversations.widget.load();
                }
            };

            return () => {
                document.body.removeChild(script);
            };
        };

        loadHubSpotChat();
    }, [sessionData]);

    return null;
};

export default HubSpotChat;